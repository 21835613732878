import $ from "jquery";

// Load when Intersection is ready
intersectionLazyLoader();

// Load When Page content is all loaded
window.addEventListener("DOMContentLoaded", event => {
  lazyItems(document.querySelectorAll("iframe, img"));
});

$(document).ajaxSuccess(function(event, xhr, settings) {
  intersectionLazyLoader();
});

function intersectionLazyLoader() {
  if ("IntersectionObserver" in window) {
    var lazyItem = document.querySelectorAll("iframe, img");
    var lazyObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (
          entry.isIntersecting &&
          entry.target.src.length == 0 &&
          !entry.target.classList.contains("lazyloaded")
        ) {
          entry.target.src = entry.target.dataset.src;
          entry.target.classList.add("lazyloaded");
          lazyObserver.unobserve(entry.target);
        }
      });
    });

    lazyItem.forEach(function(lazy) {
      lazyObserver.observe(lazy);
    });

    // if the IntersectionObserver is not available just load all iframes and images
  } else {
    var lazyItem = document.querySelectorAll("iframe, img");
    lazyItems(lazyItem);
  }
}

function lazyItems(lazyItem) {
  if (lazyItem.length) {
    for (var i = 0; i < lazyItem.length; i++) {
      if (lazyItem[i].getAttribute("data-src")) {
        lazyItem[i].setAttribute("src", lazyItem[i].getAttribute("data-src"));
        lazyItem[i].classList.add("lazyloaded");
      }
    }
  }
}
