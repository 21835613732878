import $ from "jquery";

// calculate the height of price metrics table
function priceMetricsHeight() {
  $('.price-metrics__table').each(function(){
    var priceMetricsHeight = $('.price-metrics__table').innerHeight();
    $('.js-scale-up-white').height(priceMetricsHeight);
    $('.js-scale-up-shadow').height(priceMetricsHeight);
  });
}

priceMetricsHeight();
$(window).on('resize', priceMetricsHeight);


// translateY(price-metrics__table-header) on scroll
function update() {
  if (window.matchMedia('(max-width: 767px)').matches) {
    var navHeight = $('.site-header').outerHeight();
    var mobileScreenHeight = 500;
    var pos = $(window).scrollTop();

    $('.price-metrics__table').each(function () {
      var holderHeight = $(this).outerHeight();
      var offset = $(this).offset();

      if (pos > (offset.top - navHeight)) {
        if (pos < (offset.top + holderHeight - mobileScreenHeight)) {
          var translateTop = pos + navHeight - offset.top;
        }
      } else {
        translateTop = 0;
      }
      $('.price-metrics__table-header').css("transform", `translateY(${translateTop}px)`);
    });
  } else {
    var translateTop = 0;
    $('.price-metrics__table-header').css("transform", `translateY(${translateTop}px)`);
  }
};

$(window).on('scroll', update);

// toolTip
function toolTip() {
  $('.price-metrics__table-body .tooltip').each(function () {
    var tooltipContent = $('.price-metrics__table-body .tooltip__content').outerWidth() / 2;
    var toolTipOffset = $(this).offset();
    if (toolTipOffset.left < tooltipContent) {
      $(this).removeClass('tooltip--body-left');
      $(this).addClass('tooltip--body-left');
    }
  });
}
toolTip();
$(window).on('resize', toolTip);


// smooth anchor
$('a[href*="#"]').on('click', function (event) {
  if (this.hash !== '') {
    scrollTo(this.hash);
  }
});

if (location.hash && location.hash != '' && $(location.hash).length) {
  scrollTo(location.hash);
}

function scrollTo(hash) {
  $('html, body').animate({
    scrollTop: $(hash).offset().top - 50
  }, 800);
}
