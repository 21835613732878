import $ from "jquery";
import "./menu-opener.js";
import "./post-filter.js";
import "./slider.js";
import "./gmaps.js";
import "./single-testimonial.js";
import "./cookiebar.js";
import "./lazyload.js";
import "./price-metrics.js";

// Remove transitions while page is loading and being resized
var resizeTimer;

$(document).ready(function() {
  setTimeout(function() {
    $("body").removeClass("no-transitions");
  }, 300);
});

$(window).on("resize", function(e) {
  clearTimeout(resizeTimer);
  $("body").addClass("no-transitions");

  resizeTimer = setTimeout(function() {
    $("body").removeClass("no-transitions");
  }, 250);
});
