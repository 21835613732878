import $ from "jquery";
var Flickity = require("flickity");
require("flickity-imagesloaded");

var carousel = $(
  ".post-list.limit .carousel, .post-list.selected .carousel, .single-post .carousel",
);
$.each(carousel, function() {
  var flkty = new Flickity(this, {
    contain: true,
    groupCells: true,
    cellAlign: "left",
    setGallerySize: false,
  });
});
