import $ from "jquery";
import Cookies from "js-cookie";

// Check cookie
if (Cookies.get("thepbp_site_cookie")) {
  $(".site-cookiebar").hide();
} else {
  $(".site-cookiebar").slideDown();
}

$("button.accept-site-cookie").on("click", function(e) {
  e.preventDefault();
  Cookies.set("thepbp_site_cookie", 1, { expires: 365, path: "/" });
  $(".site-cookiebar").slideUp();
});
