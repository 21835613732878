var mapBlock = document.getElementById("map");

// Where $address is json available with the map block
if (
  typeof $address != "undefined" &&
  $address != null &&
  typeof mapBlock != "undefined" &&
  mapBlock != null &&
  $address.length != 0
) {
  initMap($address);
}

function initMap(mapData) {
  // Map Options/controls with stylings
  var mapOptions = {
    center: { lat: -34.397, lng: 150.644 },
    zoom: 12,
    zoomControl: false,
    disableDefaultUI: true,
    styles: [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "administrative.province",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "administrative.neighborhood",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
    ],
  };

  // Start bounds to check the longitude and latitude of each markers
  var bounds = new google.maps.LatLngBounds();
  var map = new google.maps.Map(document.getElementById("map"), mapOptions);
  var markers = [];
  var lastWindow = null;

  mapData.forEach(data => {
    var userName = data.name;
    var userAddress = data.address;

    if (userName && userAddress) {
      userAddress.forEach(address => {
        var mapPosition = {
          lat: address.lat,
          lng: address.lng,
        };

        // Content String Html
        var contentStringHTML = infoWindowHTML(data, address.place);

        // Infowindow Configuration
        var infoWindow = new google.maps.InfoWindow({
          content: contentStringHTML,
          maxWidth: 180,
        });

        // Insert Marker
        var marker = new google.maps.Marker({
          position: mapPosition,
          map: map,
          icon: customMarker ? customMarker : false,
          animation: google.maps.Animation.DROP,
        });

        // Extend Maps position and zoom as the marker position
        bounds.extend(mapPosition);

        // Push marker to main markers
        markers.push(marker);

        // Marker on click show the infoWindow from clicked marker
        google.maps.event.addListener(marker, "click", function() {
          if (lastWindow) lastWindow.close();
          infoWindow.open(map, this);
          lastWindow = infoWindow;
        });

        // When map on change (can be changed by the clusters) hide the indoWindow
        google.maps.event.addListener(marker, "map_changed", function() {
          infoWindow.close();
        });
      });
    }
  });

  // Cluster Map Markers
  var markerCluster = new MarkerClusterer(map, markers, {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    zoomOnClick: true,
  });

  // When Bounds is centered in accordance of the map pointer set minimum level of zoom
  google.maps.event.addListenerOnce(map, "bounds_changed", function() {
    this.setZoom(Math.min(14, this.getZoom()));

    if (this.getZoom() < 3) {
      this.setZoom(1.8);
    }
  });

  // Make the map centered and zoom by checking the map markers
  map.fitBounds(bounds);
}

// Function to Print Marker's Info window HTML
function infoWindowHTML(data, address) {
  var userName = data.name;
  var place = address;
  var userEmail = data.email;
  var userProfile = data.profile;
  var userPhone = data.phone;
  var userWebsite = data.website;
  var permalink = data.permalink;

  // Marker Info window HTML
  var contentString = "";
  contentString += '<div class="coach-marker">';
  if (userProfile) {
    contentString +=
      '<div class="coach-marker__image"><img src="' +
      userProfile +
      '" alt="' +
      userName +
      '" width="150" height="150"></div>';
  }
  contentString += '<h5 class="coach-marker__name">' + userName + "</h5>";
  contentString += '<div class="coach-marker__info">';
  if (userEmail) {
    contentString +=
      '<a href="mailto:' +
      userEmail +
      '" class="coach-marker__email">Email ' +
      getFirstWord(userName) +
      " >> </a><br>";
  }
  if (userPhone) {
    contentString +=
      '<a href="tel:' +
      userPhone +
      '" class="coach-marker__phone">' +
      userPhone +
      "</a><br>";
  }
  if (userWebsite) {
    contentString +=
      '<a href="' +
      userWebsite +
      '" class="coach-marker__website" target="_blank" noreferer noopener>' +
      "Visit Website >>" +
      "</a><br>";
  }
  if (permalink != document.URL) {
    contentString +=
      '<div class="link-wrapper"><a href="' +
      permalink +
      '" class="coach-marker__link link">Read More <svg xmlns="http://www.w3.org/2000/svg" fill="#EE7203" aria-label="arrow-right" style="vertical-align:middle" viewBox="0 0 14 12" width="14" height="12"><path d="M.7 6.743h10.91l-3.755 3.988a.776.776 0 000 1.051c.274.29.717.29.99 0l4.95-5.256a.743.743 0 00.087-.114c.012-.018.02-.039.03-.058.012-.023.025-.045.034-.07.01-.025.016-.05.023-.077.006-.02.013-.04.017-.061A.787.787 0 0014 6v-.002a.794.794 0 00-.014-.144c-.004-.022-.012-.042-.018-.064-.007-.025-.012-.05-.022-.074a.632.632 0 00-.036-.074c-.01-.018-.017-.037-.028-.054a.725.725 0 00-.087-.114L8.845.218a.672.672 0 00-.99 0 .777.777 0 000 1.051l3.755 3.988H.7c-.387 0-.7.332-.7.743 0 .41.313.743.7.743z"/></svg></a></div>';
  }
  contentString += "</div>";
  contentString += "</div>";

  return contentString;
}

// Get First Word From String
function getFirstWord(str) {
  var spaceIndex = str.indexOf(" ");
  return spaceIndex === -1 ? str : str.substr(0, spaceIndex);
}
