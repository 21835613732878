import $ from "jquery";

$("a.single-tesimonial-link").on("click", function(e) {
  e.preventDefault();
  var postID = $(this).data("post-id");
  var testimonialBlock = $("body").find(
    "div.single-testimonial-popup.single-testimonial-data-" + postID,
  );

  if (testimonialBlock.length) {
    timeoutLoadingClass();
    $(testimonialBlock).show();
    $(testimonialBlock).addClass("popup-active");
    $("body").addClass("post-added");
  } else {
    if (postID) {
      getSingleTaxonomy(postID);
    }
  }
});

function getSingleTaxonomy(postID) {
  $.ajax({
    url: ajax_object.ajax_url,
    method: "post",
    data: {
      post_id: postID,
      action: "get_single_testimonial_post",
    },
    beforeSend: function() {
      $("body").addClass("post-loading");
    },
    success: function(response) {
      var ajaxData = response;
      var testimonialBlock = $("body").find(
        "div.single-testimonial-popup.single-testimonial-data-" + postID,
      );

      if (ajaxData.success) {
        $("body").append(ajaxData.data);
      } else {
        console.log("failed");
      }

      // Remove loading class from body
      timeoutLoadingClass();
    },
  });
}

$("body").on(
  "click",
  ".single-testimonial-popup .popup-closer, .single-testimonial-popup span.overlay",
  function(e) {
    e.preventDefault();
    hideTestimonialPopup();
  },
);

function timeoutLoadingClass(time = 300) {
  setTimeout(function() {
    $("body").removeClass("post-loading");
    $("body").addClass("post-added");
  }, time);
}

function hideTestimonialPopup() {
  var mainParent = $("body").find(".single-testimonial-popup.popup-active");
  mainParent.fadeOut();
  mainParent.removeClass("popup-active");
  $("body").removeClass("post-added");
}

$(document).keyup(function(e) {
  if (e.keyCode === 27) {
    hideTestimonialPopup();
  }
});
