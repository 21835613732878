import $ from "jquery";

$(".menu-opener").on("click", function(e) {
  e.preventDefault();
  $("body").toggleClass("menu-active");
});

$("svg.down-icon").on("click", function(e) {
  e.preventDefault();
  var dropdownMenu = $(this)
    .parent(".menu-item")
    .find("ul.menu-dropdown");
  var menuParent = $(this).parent(".menu-item");

  if (dropdownMenu) {
    dropdownMenu.slideToggle();
    menuParent
      .siblings("li.menu-item-has-children")
      .find("ul.menu-dropdown")
      .slideUp();
  }
});
