// Taxonomy-post-sorting
import $ from "jquery";
$("a.tax-filter-link").on("click", function(e) {
  e.preventDefault();
  // Toggle Active state
  $(this)
    .parent("li")
    .addClass("active");

  $(this)
    .parent("li")
    .siblings("li")
    .removeClass("active");

  // Do the sorting
  var targetTaxonomy = $(this).data("taxonomy");
  var postsList = $(this).parents("section.post-list");
  var postsWrapper = postsList.find(".post-list-coach");
  var singlePost = postsWrapper.find("article");
  var matchingPost = singlePost.hasClass(targetTaxonomy);

  if (targetTaxonomy == "all_posts") {
    singlePost.addClass("active");
    singlePost.removeClass("hide");
  } else {
    if (singlePost.length) {
      $.each(singlePost, function(index) {
        if ($(this).hasClass(targetTaxonomy)) {
          $(this).addClass("active");
          $(this).removeClass("hide");
          // console.log($(this).hasClass(targetTaxonomy))
        } else {
          $(this).removeClass("active");
          $(this).addClass("hide");
        }
      });
    }
  }
});

$("li.testimonial_category").on("click", function() {
  var target_short = $(this).attr("data-short-slug");
  $(".taxonomy-filter-list .taxonomy-item a[data-taxonomy="+target_short+"]").click();
});
